<template>
  <div class="flex-grow flex flex-row items-stretch justify-center">
    <div class="flex-grow max-w-670px">
      <Messenger class="h-full" />
    </div>
  </div>
</template>

<script>
import Messenger from '@/components/Messenger/Messenger.vue';

export default {
  name: 'RequestCommentsAndHistoryMessenger',
  components: {
    Messenger,
  },
};
</script>
